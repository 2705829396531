const localStorageKey = "__auth_provider_token__";

const getToken = async () => {
  return window.localStorage.getItem(localStorageKey);
};

export const handleUserResponse = ({ user, token }) => {
  window.localStorage.setItem(localStorageKey, token);
  window.location.replace(`${window.location.origin}/dashboard`);
  // window.location.href = `${window.location.origin}/dashboard`;
  return user;
};

const login = ({ email, password }) => {
  return client("login", { email, password }).then(handleUserResponse);
};

const register = ({ fname, lname, email, password }) => {
  return client("signup", { fname, lname, email, password }).then(
    handleUserResponse
  );
};

const logout = async () => {
  window.localStorage.removeItem(localStorageKey);
  window.location.replace(`${window.location.origin}/`);
};

const authURL = process.env.REACT_APP_API_ROOT + "/users";

const client = async (endpoint, data) => {
  const config = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  };

  return window
    .fetch(`${authURL}/${endpoint}`, config)
    .then(async (response) => {
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
};

export { getToken, login, register, logout, localStorageKey };
