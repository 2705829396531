import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import AppProviders from "./context";

// const getDefaultTheme = () =>
//   window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
//     ? "darkTheme"
//     : "light";

// export const Index = () => {
//   const [themeType, setThemeType] = useState(getDefaultTheme());

//   const switchThemes = () => {
//     setThemeType(last => (last === "darkTheme" ? "light" : "darkTheme"));
//   };

//   if (window.matchMedia) {
//     const colorSchemeQuery = window.matchMedia("(prefers-color-scheme: dark)");
//     colorSchemeQuery.onchange = e =>
//       setThemeType(e.matches ? "darkTheme" : "light");
//   }

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
  document.getElementById("root")
);
