import * as React from "react";
import * as auth from "../auth-provider";
import { FullPageSpinner } from "../components/lib";
import { client } from "../utils/api-client";
import { useAsync } from "../utils/hooks";

const getUser = async () => {
  try {
    let user = null;
    const token = await auth.getToken();
    if (token) {
      const data = await client("users/me", { token });
      user = data.user;
      user.token = token;
    }
    return user;
  } catch (error) {
    console.log("error getting user, logging out...", error);
    auth.logout();
  }
};

const AuthContext = React.createContext();
AuthContext.displayName = "AuthContext";

const AuthProvider = (props) => {
  const {
    data: user,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
    status,
  } = useAsync();

  React.useEffect(() => {
    run(getUser());
  }, [run]);

  const login = React.useCallback(
    (form) => auth.login(form).then((user) => setData(user)),
    [setData]
  );
  const register = React.useCallback(
    (form) => auth.register(form).then((user) => setData(user)),
    [setData]
  );
  const logout = React.useCallback(() => {
    auth.logout();
    setData(null);
  }, [setData]);

  const value = React.useMemo(() => ({ user, login, logout, register }), [
    login,
    logout,
    register,
    user,
  ]);

  if (isLoading || isIdle) {
    return <FullPageSpinner />;
  }
  // TODO: Set up error boundaries to display error messages
  if (isError) {
    return <div>{error.message}</div>;
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status: ${status}`);
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthContext provider`);
  }
  return context;
};

const useClient = () => {
  const { user } = useAuth();
  const token = user?.token;
  return React.useCallback(
    (endpoint, config) => client(endpoint, { ...config, token }),
    [token]
  );
};

export { AuthProvider, useAuth, useClient };
