import { Spinner, useTheme } from "@geist-ui/react";
import React from "react";

const FullPageSpinner = () => {
  const { palette } = useTheme();
  return (
    <div
      style={{
        backgroundColor: palette.background,
        fontSize: "4em",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner size="large" />
    </div>
  );
};

export { FullPageSpinner };
