import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./auth-context";
import { GeistProvider, CssBaseline } from "@geist-ui/react";
import { darkTheme } from "../theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const AppProviders = ({ children }) => {
  return (
    <Router>
      <GeistProvider themes={[darkTheme]} themeType="darkTheme">
        <CssBaseline />
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            {children}
          </QueryClientProvider>
        </AuthProvider>
      </GeistProvider>
    </Router>
  );
};

export default AppProviders;
