import { Themes } from "@geist-ui/react";

export const darkTheme = Themes.createFromDark({
  type: "darkTheme",
  palette: {
    accents_1: "#191923",
    accents_2: "#22222E",
    accents_3: "#4A5568",
    accents_4: "#718096",
    accents_5: "#A0AEC0",
    accents_6: "#CBD5E0",
    accents_7: "#E2E8F0",
    accents_8: "#EDF2F7",
    background: "#111119",
    foreground: "#fff",
    error: "#E53E3E",
    errorLight: "#E53E3E",
    errorDark: "#E53E3E",
    success: "#48bb78",
    successLight: "#68d391",
    successDark: "#38A169",
    warning: "#ed8936",
    warningLight: "#f6ad55",
    warningDark: "#f56565",
    violet: "#5A67D8",
    purple: "#9f7aea",
    alert: "#ED64A6",
    selection: "#f81ce5",
    secondary: "#9ca3af",
    code: "#79ffe1",
    border: "#1f2937",
    link: "#667EEA",
  },
});
