import { Page } from "@geist-ui/react";
import React from "react";
import { FullPageSpinner } from "./components/lib";
import { useAuth } from "./context/auth-context";

const AuthenticatedApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./authenticated-app")
);
const UnauthenticatedApp = React.lazy(() => import("./unauthenticated-app"));

const App = () => {
  const { user } = useAuth();

  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      <Page size="xlarge" style={{ padding: `-30px` }}>
        {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </Page>
    </React.Suspense>
  );
};

export default App;
